$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Mountains of Christmas', cursive;

@import "base";
@import "santa";
@import "wheel";
@import "fireworks";

@import "responsive-1920px";
@import "responsive-1450px";
@import "responsive-1199px";
@import "responsive-991px";
@import "responsive-768px";
