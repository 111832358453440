

.santa {
    position: absolute;
    left: 100%;
    animation: santa 20s infinite;
    animation-timing-function: linear;
    z-index: 2;
    pointer-events: none;

    svg {
        transform: scale(2);
    }
}

@-webkit-keyframes santa{
    100%{
        left: -400px;
    }
}

.plane{
    position: relative;
    -webkit-animation: float 3s infinite;
    animation: float 3s infinite;
}
@-webkit-keyframes float{
    50%{
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
}
@keyframes float{
    50%{
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
}
.hand{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    -webkit-animation: wave 1.5s infinite;
    animation: wave 1.5s infinite;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}
.blade{
    -webkit-animation:spin 1s infinite linear;
    animation:spin 1s infinite linear;
    -webkit-transform-origin: 50% 54%;
    -ms-transform-origin: 50% 54%;
    transform-origin: 50% 54%;
}
@-webkit-keyframes spin{
    100%{
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}
@keyframes spin{
    100%{
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}
@-webkit-keyframes wave{
    50%{
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}
@keyframes wave{
    50%{
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}
