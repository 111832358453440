body {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b1060c+0,590306+100 */
    background: rgb(177,6,12); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(177,6,12,1) 0%, rgba(89,3,6,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(177,6,12,1) 0%,rgba(89,3,6,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(177,6,12,1) 0%,rgba(89,3,6,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1060c', endColorstr='#590306',GradientType=1 ); /* IE6-9 */
    font-family: $font-primary;
    font-size: 19px;
    line-height: 1.5;
    margin: 0;
    position: relative;

    &.stream {
        .bg__top__right,
        .bg__bot__right,
        .bg__bot__left {
            display: none;
        }

        .bg__top__left {
            z-index: 99991;
            width: 356px;
            height: 241px;
        }

        .santa {
            bottom: -135px;
        }

        .santa svg {
            transform: scale(1.5);
        }
    }

    &::after {
        content: '';
        background-image: url('../images/bg-stars.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
    }
}

.btn {
    background-color: #9F050B;
    box-shadow: none;
    border: none;
    text-transform: uppercase;
    color: #fff;
    border-radius: 100px;
    font-size: 17px;
    font-weight: bold;
    height: 60px;
    padding: 0 30px;
    cursor: pointer;
}

.bg__top__left {
    background-image: url('../images/bg-top-left.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 470px;
    height: 310px;
    pointer-events: none;
}

.bg__top__right {
    background-image: url('../images/bg-top-right.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    width: 371px;
    height: 254px;
    pointer-events: none;
}

.bg__bot__left {
    background-image: url('../images/bg-bot-left.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 402px;
    height: 347px;
    pointer-events: none;
}

.bg__bot__right {
    background-image: url('../images/bg-bot-right.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 416px;
    height: 313px;
    pointer-events: none;
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    //max-height: 100vh;
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
    overflow: auto;
}

.stanta__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    overflow: hidden;
}

.vimeo__iframe {
    min-height: 340px;
    height: 50%;
}

.stream__entries__title {
    font-family: "Mountains of Christmas", cursive;
    font-size: 32px;
    line-height: 43px;
    padding-bottom: 10px;
    padding-top: 100px;
    padding-left: 15px;
    color: #930000;
    font-weight: 700;
    margin: 0;

    span {
        display: block;
    }
}

.stream__entries {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .stream__entries__inner {
        padding-right: 5px;
        overflow: auto;
        height: 400px;
    }

    .entry__block {
        margin-left: 20px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .entry__content {
            padding: 20px 30px 20px 40px;

            .entry__title {
                font-size: 20px;
                line-height: 27px;
                margin: 0;
            }

            .entry__company{
                color: #9b6a50;
                font-size: 15px;
                margin: 0;
            }
        }

        .entry__count {
            background: #e5e5e5;
            width: 35px;
            height: 35px;
            color: #4b3f32;
            position: absolute;
            border-radius: 100%;
            left: -17px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
        }

        .entry__time {
            background: #fff7f7;
            color: #930000;
            padding: 20px 10px;
            border-radius: 0 5px 5px 0;
            font-size: 13px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }
}

.form__block {
    position: absolute;
    left: 10%;
    top: 50px;
    background-image: url('../images/form-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 820px;
    height: 1060px;
    display: flex;
    justify-content: center;
    z-index: 3;
    padding: 190px 80px 0;

    form {
        margin-top: 35px;

        .btn {
            float: right;
        }
    }
}

.form__inner {
    position: relative;
    max-width: 680px;
}

.form__text {
    text-align: center;
}

.form__title {
    font-size: 47px;
    margin-bottom: 20px;
    color: #910000;
    font-family: $font-secondary;
    font-weight: bold;
}

.form__control {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &.error {
        input,
        textarea {
            background: #ffe2e2;

            &:active,
            &:not(:placeholder-shown),
            &:focus {
                background-color: #fff;

                &::placeholder {
                    color: #fff;
                }
            }


            &::placeholder {
                color: red;
                font-size: 14px;
            }
        }
    }

    label {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #910000;
    }

    input {
        height: 63px;
        border-radius: 100px;
        padding: 0 20px;
    }

    textarea {
        min-height: 170px;
        border-radius: 25px;
        resize: vertical;
        padding: 15px 20px;
    }

    input,
    textarea {
        background-color: #fff;
        border: 1px solid #eee;
        box-shadow: 4px 5px 0 0 #d6b987;
        font-size: 17px;
        font-family: $font-primary;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }
    }
}

.form__logo__wrap {
    position: absolute;
    right: calc(25% - 300px);

    .form__livestream__hint {
        position: relative;
        overflow: hidden;

        &::after {
            content: 'Livestream';
            font-family: "Mountains of Christmas", cursive;
            display: flex;
            justify-content: center;
            width: 125px;
            position: absolute;
            left: -30px;
            top: 40px;
            transform: rotate(-45deg);
            color: #910000;
            padding: 5px 30px;
            font-size: 24px;
            background: #fff;
            font-weight: 700;
        }

        img {
            width: 100%;
            max-width: 600px;
            border: 15px solid #fff;
            border-radius: 14px;
        }
    }

    .form__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        z-index: 0;
    }
}

.stream__block {
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    opacity: 0;
    animation: opacity 300ms forwards;
    animation-delay: 500ms;
}

@-webkit-keyframes opacity{
    100%{
        opacity: 1;
    }
}

.stream__left {
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 496px;
    min-width: 496px;
    max-width: 496px;
    background: #fff;
}

.stream__right {
    width: 100%;
    padding: 45px;
}

.stream__logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .line1 {
        position: absolute;
        left: 0;
        width: calc(50% - 60px);
        height: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::before {
            content: '';
            width: 100%;
            background-color: rgba(#fff, 0.1);
            height: 3px;
            display: inline-block;
        }

        &::after {
            content: '';
            width: 100%;
            background-color: rgba(#fff, 0.1);
            height: 3px;
            display: inline-block;
        }
    }

    .line2 {
        position: absolute;
        right: 0;
        width: calc(50% - 60px);
        height: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::before {
            content: '';
            width: 100%;
            background-color: rgba(#fff, 0.1);
            height: 3px;
            display: inline-block;
        }

        &::after {
            content: '';
            width: 100%;
            background-color: rgba(#fff, 0.1);
            height: 3px;
            display: inline-block;
        }
    }

    img {
        width: 60px;
    }
}

.stream__video {
    margin: 50px auto 0;
    border: 12px solid #fff;
    border-radius: 15px;
    background-color: #fff;
    position: relative;
    max-width: 1200px;

    iframe {
        border-radius: 6px;
    }
}

.cloud1 {
    position: absolute;
    top: -23px;
    left: -12px;
    z-index: 9;

    img {
        width: 160px;
    }
}

.cloud2{
    position: absolute;
    top: -36px;
    right: -39px;
    z-index: 9;

    img {
        width: 78px;
    }
}

.wheel__block {
    width: 100%;
    height: 100%;
    z-index: 99;
    min-height: 100vh;

    .stream__logo {
        max-width: 916px;
        width: 100%;
        margin: 50px auto 20px;
        height: 77px;
    }
}

.wheel__bottom {
    display: flex;
    height: calc(100vh - 147px);
}

.wheel__left__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1116px;
    height: 1116px;
    margin: 0 auto;
    position: relative;

    canvas {
        border: 8px solid #711723;
        border-radius: 100%;
        overflow: hidden;
        box-shadow: 0 0 24px rgba(#000, 0.25);
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 20px 0 20px;
        border-color: #711723 transparent transparent transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
}

.wheel__left {
    flex: 0 0 calc(100% - 740px);
    transition: all 2000ms ease-in-out;
    transform: scale(1);
    position: relative;
    top: 0;
    z-index: 2;
}

.wheel__left__bg {
    background-color: rgba(#930000, 0.8);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.wheel__scale {
    .wheel__left {
        transform: scale(1.25);
        top: 30px;
        flex: 0 0 100%;
        transition: all 2000ms ease-in-out;
    }

    .wheel__left__bg {
        opacity: 1;
        transition: all 2000ms ease-in-out;
    }
}

.wheel__right {
    min-width: 740px;
    max-width: 740px;
    width: 740px;
    position: absolute;
    right: 0;
}

.wheel__form {
    background-image: url('../images/form-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 737px;
    height: 947px;
    transform: rotate(-7deg);
    position: relative;
    margin-top: 75px;
}

.wheel__form__inner {
    display: flex;
    flex-direction: column;
    padding: 165px 105px;
    height: 640px;
    transform: rotate(2deg);
}

.wheel__title {
    display: flex;
    margin-bottom: 30px;
}

.wheel__title__left {
    width: 100%;
}

.wheel__title__right {
    width: 100px;
}

.wheel__name {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-bottom: 3px;
}

.wheel__time {
    color: #930000;
    font-size: 15px;
}

.wheel__company {
    font-size: 15px;
    color: #910000;
}

.wheel__text {
    font-family: $font-secondary;
    font-size: 51px;
    font-weight: bold;
    color: #930000;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

