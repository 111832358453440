@media screen and (max-width: 767px) {
    .form__logo__wrap {
        display: none;
        position: absolute;
        bottom: 10px;
        max-width: 300px;
        z-index: 5;

        .form__logo {
            margin: 40px 30px 0 0;

            img {
                width: 50px;
            }
        }

    }

    .stream__entries__title {
        padding-top: 10px;

        span {
            display: inline-block;
        }
    }

    .stream__entries {
        .stream__entries__inner {
            height: 200px;
        }
    }

        .form__block {
        left: 0;
        position: relative;
        top: initial;
        background-position: center;
        background-size: cover;
        margin-top: 0;
        width: 90%;
        padding: 0;
    }

    .main {
        max-height: initial;
        padding: 15px 0 15px;
    }

    .form__inner {
        padding: 20px 70px;
    }

    .stream__block {
        flex-direction: column;
    }

    .stream__left {
        order: 2;
        width: calc(100% - 30px);
        min-width: calc(100% - 30px);
        max-width: calc(100% - 30px);
    }

    .stream__right {
        order: 1;
        padding: 0;
    }

    .stream__video {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    body.stream .bg__top__right {
        display: block;
        width: 164px;
        height: 114px;
    }

    .stream__logo {
        margin: 40px 0;
    }

    .stream__logo .line1,
    .stream__logo .line2 {
        width: calc(50% - 60px);
    }

    .cloud1 {
        left: 0;
    }

    .cloud2 {
        top: -41px;
        right: -30px;
    }

    .stream__left iframe {
        min-height: 450px;
        float: left;
    }

    .stream .main {
        padding: 0;
    }

    body.stream .santa {
        top: -130px;
    }

    body.stream .santa svg {
        transform: scale(0.5);
    }
}

@media screen and (max-width: 525px) {
    .form__inner {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 450px) {
    .form__logo__wrap {
        max-width: 230px;

        .form__livestream__hint {
            &::after {
                width: 70px;
                font-size: 13px;
                left: -30px;
                top: 20px;
            }
        }
    }
}
