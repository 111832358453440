@media screen and (max-width: 1450px) {
    .form__block {
        left: 30px;
    }

    .form__logo__wrap {
        position: absolute;
        right: calc(25% - 200px);

        .form__livestream__hint {
            position: relative;
            overflow: hidden;

            &::after {
                content: 'Livestream';
                top: 35px;
                left: -32px;
                font-size: 21px;
            }

            img {
                width: 100%;
                max-width: 400px;
                border: 10px solid #fff;
                border-radius: 14px;
            }
        }

        .form__logo {
            margin-bottom: 40px;
        }
    }
}
