.fireworks__block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    color: #fff;
    opacity: 0;
    transition: all 300ms ease-in-out;

    &.fade__in {
        opacity: 1;
        transition: all 300ms ease-in-out;
    }
}

.title {
    font-family: 'Pacifico', cursive;
    font-size: clamp(36px, 0.6rem, 100px);
    color: #fff;
    text-shadow: 6px 4px #735b13;
}

[class^='firework-'] {
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
}

$count: 15;
$frame-list: firework-sm, firework-md, firework-lg;
@for $i from 1 through $count {
    .firework-#{$i} {
        animation: nth($frame-list, random(3)) 1.2s both infinite;
        animation-delay: #{random($count) * 0.1}s;
        top: random(16) * 5% + 10%;
        left: random(16) * 5% + 10%;
    }
}


// dots generator
@function dots($r) {
    $result: ();
    // straight
    @for $i from 1 through 4 {
        $x: 0;
        $y: 0;
        @if $i <= 2 {$x: $r} @else {$y: $r}
        @if $i % 2 != 0 {$x: -$x; $y: -$y}
        $result: append($result, #{$x}rem #{$y}rem 0 #fff, comma);
    }
    // diagonal
    @for $i from 1 through 4 {
        $dist2: $r * 0.7;
        $x: $dist2;
        $y: $dist2;
        @if $i > 2 {$x: -$x}
        @if $i % 2 != 0 {$y: -$y}
        $result: append($result, #{$x}rem #{$y}rem 0 #fff, comma);
    }
    @return $result;
}

// firework animation
@mixin fireworkSize($name, $r) {
    @keyframes firework-#{$name} {
        0%,
        100% {
            opacity: 0;
        }
        10%,
        70% {
            opacity: 1;
        }
        100% {
            box-shadow: dots($r);
        }
    }
}
@include fireworkSize(sm, 0.5);
@include fireworkSize(md, 0.7);
@include fireworkSize(lg, 0.9);

.fireworks__text {
    color: yellow;
    text-shadow: 0 0 15px yellow, 0 0 25px yellow;
    animation: glow 5s linear infinite;
    font-size: 100px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .fireworks__text__inner {
        background-color: rgba(#930000, 0.8);
        padding: 50px 50px;
        margin-top: -90px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        b {
            font-size: 180px;
            margin-top: 50px;
        }
    }
}

@keyframes glow {
    0% {
        filter: hue-rotate(0deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}
