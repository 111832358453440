@media screen and (max-width: 1900px) {
    body {
        font-size: 16px;
    }

    .stream__entries__title {
        padding-top: 40px;
    }

    .form__title {
        font-size: 34px;
        margin-bottom: 5px;
    }

    .form__block form {
        margin-top: 20px;
    }

    .form__block {
        width: 456px;
        height: 650px;
        padding: 110px 70px 0;
        top: initial;
    }

    .form__control input {
        height: 42px;
        font-size: 14px;
    }

    .form__control {
        margin-bottom: 20px;
    }

    .form__control label {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .form__control textarea {
        min-height: 84px;
        font-size: 14px;
    }

    .bg__top__left {
        width: 250px;
        height: 173px;
    }

    body.stream .bg__top__left {
        width: 250px;
        height: 173px;
    }

    .bg__top__right {
        width: 248px;
        height: 170px;
    }

    .bg__bot__left {
        width: 250px;
        height: 216px;
    }

    .bg__bot__right {
        width: 256px;
        height: 193px;
    }

    .santa svg{
        transform: scale(1);
    }

    .form__logo img {
        width: 100px;
    }

    .btn {
        font-size: 15px;
        height: 46px;
        padding: 0 20px;
    }

    .stream__video {
        margin-top: 30px;
    }

    .stream__left {
        width: 330px;
        min-width: 330px;
        max-width: 330px;
    }

    .stream__right {
        padding: 25px 30px;
    }

    body.stream .santa {
        bottom: -140px;
    }

    body.stream .santa svg {
        transform: scale(1);
    }
}
