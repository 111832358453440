@media screen and (max-width: 991px) {
    .stream__entries__title {
        font-size: 24px;
    }

    .form__logo__wrap {
        max-width: 300px;
        right: 0;

        .form__livestream__hint {
            &::after {
                font-size: 16px;
                top: 16px;
                left: -60px;
            }
        }
    }

    .stream__entries {
        .stream__entries__inner {
            height: 320px;
        }

        .entry__block {
            .entry__count {
                height: 25px;
                width: 25px;
                font-size: 14px;
            }

            .entry__time {
                padding: 12px 10px;
            }

            .entry__content {
                padding: 12px 10px 12px 25px;

                .entry__title {
                    font-size: 17px;
                    line-height: 24px;
                }
            }
        }
    }

    .stream__left {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
    }

    body.stream .bg__top__left {
        width: 171px;
        height: 118px;
    }
}

@media screen and (max-width: 881px) {
    .form__logo__wrap {
        max-width: 200px;
    }
}
