@media screen and (max-width: 1199px) {
    .form__logo img {
        width: 75px;
    }

    .form__logo__wrap {
        right: 30px;
    }

    .stream__left {
        width: 325px;
        min-width: 325px;
        max-width: 325px;
    }
}
